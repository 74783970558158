<template>
    <el-container style="height:calc(100vh);">
      <el-header>
        <span class="left el-icon-arrow-left" @click="returnOn"></span>
        修改积分设置
        <div class="right" @click="postpoints">保存</div>
      </el-header>
      <el-main class="main">
        <div class="content">
          <div class="box" >
            <div class="box-item">
              <div class="left">积分抵扣比例</div>
              <div class="r-ipt" v-if="info[0]">
                <el-input v-model="info[0].value"  type="number" placeholder="输入抵扣比制"></el-input>
              </div>
            </div>
            <div class="box-item" v-if="info[1]">
              <div class="left">积分最高抵扣金额</div>
              <div class="r-ipt" >
                <el-input v-model="info[1].value"  type="number" placeholder="输入金额"></el-input>
              </div>
            </div>
            <div class="box-item" v-if="info[2]">
              <div class="left">最高抹零金额</div>
              <div class="r-ipt">
                <el-input v-model="info[2].value"  type="number" placeholder="输入金额"></el-input>
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </template>
  
  <script>
    export default {
      data() {
        return {
          priceInput1: '',
          priceInput2: '',
          priceInput3: '',
          info:[]
        }
      },
      created() {
        this.getpoints()
      },
      methods: {
        //传递
        postpoints(){
            this.$http.post('api/store/storeset/xiugaiyoouhuo',{
                name:this.info
            }).then(res =>{
                this.$message.success(res.data.msg)
            })
        },
        //获取比例
        getpoints(){
            this.$http.post('api/store/storeset/storeyouhui').then(res =>{
                console.log(res);
                this.info = res.data.data
            })
        },
        // 返回上级
        returnOn() {
          this.$router.push({
            name: 'PreferentialSet'
          })
        },
  
      }
    };
  
  </script>
  <style lang="less" scoped>
    .el-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      font-weight: bold;
      font-size: 16px;
      color: #333;
      height: 50px !important;
      line-height: 50px;
      text-align: center;
      border-bottom: 1px solid #e9e9e9;
  
      .left {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #666;
  
        &::before {
          color: #46a6ff;
          font-size: 18px;
          line-height: 18px;
        }
      }
  
      .right {
        color: #46a6ff;
        font-weight: normal;
        font-size: 14px;
      }
    }
  
    .main {
      background-color: #f9f9f9;
    }
  
    .content {
      padding: 20px;
  
      .cell-t {
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0 0 2px #ccc;
        padding: 10px 20px;
        background-color: #fff;
        margin-bottom: 15px;
  
        .title {
          line-height: 24px;
        }
  
      }
  
      .box {
        padding: 10px;
        background-color: #fff;
        border: 1px solid #f2f2f2;
        color: #333;
        margin-bottom: 20px;
  
        .box-title {
          line-height: 40px;
          padding: 0 10px;
          border-bottom: 1px solid #f2f2f2;
        }
  
        .box-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          line-height: 50px;
          border-bottom: 1px solid #f2f2f2;
          padding: 0 10px;
  
          &:last-child {
            line-height: 40px;
            border-bottom: none;
          }
  
          .el-icon-check {
            font-weight: bold;
            color: #46a6ff;
            display: none;
          }
  
          .right {
            display: block;
          }
  
          .r-ipt {
            display: flex;
  
            /deep/ .el-input {
              .el-input__inner {
                text-align: right;
                border: none;
              }
            }
          }
        }
      }
  
    }
  
  </style>
  